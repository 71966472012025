import React from "react"
import { projectContainer, tag, links } from "../styles/projects.module.css"

const Project = ({ project }) => {
  const repo = project.node

  return (
    <div className={projectContainer}>
      <h2>{repo.name}</h2>
      <p>{repo.description}</p>
      {repo.languages.edges.map(language => (
        <span
          className={`${tag} ${language.node.name.toLowerCase()}`}
          key={language.node.id}
        >
          {language.node.name}
        </span>
      ))}
      <div className={links}>
        <a
          className="btn"
          href={repo.url}
          target="_blank"
          rel="noopener noreferrer"
          title="Go to project repository"
        >
          View Code
        </a>
        {repo.homepageUrl && (
          <a
            className="btn"
            href={repo.homepageUrl}
            target="_blank"
            rel="noopener noreferrer"
            title="View a live preview of project"
          >
            View Live
          </a>
        )}
      </div>
    </div>
  )
}

export default Project
