import React from "react"
import {
  welcome,
  container,
  social,
  socialIcon,
  emailBtn,
} from "../styles/welcome.module.css"
import githubIcon from "../images/github-icon.svg"
import linkedinIcon from "../images/linkedin-icon.svg"
import twitterIcon from "../images/twitter-icon.svg"
import instagramIcon from "../images/instagram-icon.svg"
import facebookIcon from "../images/facebook-icon.svg"

const WelcomeSection = () => {
  return (
    <section className={welcome}>
      <div className={container}>
        <h1>
          I'm Hassan{" "}
          <span role="img" aria-label="Wave Emoji">
            &#128075;
          </span>
        </h1>
        <p> - I specialise in Web Development and Cybersecurity.</p>
        <div className={social}>
          <a
            href="https://github.com/HassanCorrigan"
            className={socialIcon}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Github Logo"
            title="Go to Hassan's GitHub profile"
          >
            <img src={githubIcon} alt="Logo" />
          </a>
          <a
            href="https://linkedin.com/in/HassanCorrigan"
            className={socialIcon}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn Logo"
            title="Go to Hassan's LinkedIn profile"
          >
            <img src={linkedinIcon} alt="LinkedIn Logo" />
          </a>
          <a
            href="https://twitter.com/HassanCorrigan"
            className={socialIcon}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Twitter Logo"
            title="Go to Hassan's Twitter profile"
          >
            <img src={twitterIcon} alt="Twitter Logo" />
          </a>
          <a
            href="https://instagram.com/HassanCorrigan"
            className={socialIcon}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram Logo"
            title="Go to Hassan's Instagram profile"
          >
            <img src={instagramIcon} alt="Instagram Logo" />
          </a>
          <a
            href="https://facebook.com/HassanCorrigan"
            className={socialIcon}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook Logo"
            title="Go to Hassan's Facebook profile"
          >
            <img src={facebookIcon} alt="Facebook Logo" />
          </a>
        </div>
        <a
          className={emailBtn}
          href="mailto:contact@hassancorrigan.com?subject=Website%20Contact"
          title="Send email button"
        >
          <span>Email Me</span>
        </a>
      </div>
    </section>
  )
}

export default WelcomeSection
