import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  recentPosts,
  postsContainer,
  post,
  content,
  title,
  meta,
  moreBtn,
} from "../styles/recent-posts.module.css"

const RecentPostsSection = () => {
  const data = useStaticQuery(
    graphql`
      {
        allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: DESC }
          limit: 3
        ) {
          totalCount
          edges {
            node {
              id
              frontmatter {
                title
                date(fromNow: true)
                featuredImage {
                  name
                  childImageSharp {
                    gatsbyImageData(width: 300, layout: FIXED)
                  }
                }
              }
              fields {
                slug
              }
              timeToRead
            }
          }
        }
      }
    `
  )

  return (
    <section className={recentPosts}>
      <h2>
        <span role="img" aria-label="Technologist Emoji">
          &#128104;&#127997;&#8205;&#128187;
        </span>{" "}
        Recent Posts
      </h2>
      <div className={postsContainer}>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div key={node.id} className={post}>
            <Link to={node.fields.slug} aria-label={node.frontmatter.title}>
              <GatsbyImage
                image={
                  node.frontmatter.featuredImage.childImageSharp.gatsbyImageData
                }
                alt={node.frontmatter.featuredImage.name}
              />
              <div className={content}>
                <p className={title}>{node.frontmatter.title}</p>
                <span className={meta}>
                  {node.frontmatter.date.toLocaleString("en-US")} -{" "}
                  {node.timeToRead} minute read
                </span>
              </div>
            </Link>
          </div>
        ))}
        <Link to="/blog" className={moreBtn} title="View more blog posts">
          More Posts
        </Link>
      </div>
    </section>
  )
}

export default RecentPostsSection
