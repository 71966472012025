import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Project from "./Project"
import { projectsSection, button } from "../styles/projects.module.css"

const Projects = () => {
  const [projects, setProjects] = useState([])
  const [isBtnShown, setIsBtnShown] = useState(true)
  const githubRepoData = useStaticQuery(graphql`
    query {
      github {
        viewer {
          login
          repositories(
            first: 100
            orderBy: { field: CREATED_AT, direction: DESC }
          ) {
            edges {
              node {
                id
                homepageUrl
                name
                description
                url
                languages(first: 10) {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const repos = githubRepoData.github.viewer.repositories.edges
  useEffect(() => {
    // Show first 5 projects
    setProjects(repos.slice(0, 5))
  }, [repos])

  const loadAllProjects = () => {
    // Load all projects and hide button
    setProjects(repos)
    setIsBtnShown(false)
  }

  return (
    <section className={projectsSection}>
      <h2>
        <span role="img" aria-label="Octopus Emoji">
          &#128025;
        </span>{" "}
        GitHub Projects
      </h2>
      {projects.map(repo => (
        <Project key={repo.node.id} project={repo} />
      ))}
      {isBtnShown && (
        <button className={button} onClick={loadAllProjects}>
          Load All Projects
        </button>
      )}
    </section>
  )
}

export default Projects
