import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import WelcomeSection from "../components/WelcomeSection"
import WebsitesSection from "../components/WebsitesSection"
import ProjectsSection from "../components/ProjectsSection"
import RecentPostsSection from "../components/RecentPostsSection"

const Home = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <WelcomeSection />
      <WebsitesSection />
      <ProjectsSection />
      <RecentPostsSection />
    </Layout>
  )
}

export default Home
