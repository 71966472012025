import React from "react"
import Area9Logo from "../images/area9-logo.png"
import MBWindowsLogo from "../images/mbwindows-logo.png"
import RocheWindowsLogo from "../images/rochewindows-logo.png"
import { websites, companies, logo } from "../styles/websites.module.css"

const WebsitesSection = () => {
  return (
    <section className={websites}>
      <h2>
        <span role="img" aria-label="Chain Link Emoji">
          &#128279;
        </span>{" "}
        Websites
      </h2>
      <p>
        Some of the companies i've worked with to create their own professional
        websites from concept to deployment, with custom design language and
        features.
      </p>
      <div className={companies}>
        <a
          href="https://www.area9.ie"
          target="_blank"
          rel="noopener noreferrer"
          title="Go to Area9 homepage"
        >
          <img className={logo} src={Area9Logo} alt="Area9 Logo" />
        </a>
        <a
          href="https://www.mbwindows.ie"
          target="_blank"
          rel="noopener noreferrer"
          title="Go to MB Windows homepage"
        >
          <img className={logo} src={MBWindowsLogo} alt="MB Windows Logo" />
        </a>
        <a
          href="https://www.rochewindows.com"
          target="_blank"
          rel="noopener noreferrer"
          title="Go to Roche Windows homepage"
        >
          <img
            className={logo}
            src={RocheWindowsLogo}
            alt="Roche Windows Logo"
          />
        </a>
      </div>
    </section>
  )
}

export default WebsitesSection
